import React, { useContext, useState } from "react";
import { CarerWizardContext } from "./AddCarer";
import { Form } from "reactstrap";
import {
  ActionButton,
  BackButton,
  Block,
  Col,
  FormInput,
  FormInputType,
  IconButton,
  PhoneInput,
  Row,
} from "../../../components/Component";
import { useEffect } from "react";
import NameTitleDropDown from "../../../components/dropdowns/NameTitleDropDown";
import { CarerPreviousButton } from "../../../components/button/ActionButton";

const CarereContact = ({ onPrev, onNext, isValid }) => {
  const { data, updateData } = useContext(CarerWizardContext);
  const [isDisable, setIsDisable] = useState(true);
  const [itHasChanges, setItHasChanges] = useState(false);

  useEffect(() => {
    // const hasEmptyFields =
    //   data.contacts?.some((item) => item.title === "") ||
    //   data.contacts?.some((item) => item.firstName === "") ||
    //   data.contacts?.some((item) => item.lastName === "") ||
    //   data.contacts?.some((item) => item.email === "");
    // // data.contacts.some((item) => !item.phoneNumber || item.phoneNumber?.phoneNumber === 0
    // // );
    // const hasErrors = data?.isFirstNameError || data?.islastNameError || data?.isEmailError || data?.isPhoneError;

    setIsDisable(false);
    isValid(false, itHasChanges);
  }, [data]);

  const handleAddContact = () => {
    let resourceLinkList = data?.contacts;
    resourceLinkList.push({
      title: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      Address: "",
      relationship: "",
    });
    updateData("contacts", resourceLinkList);
  };

  const handleRemoveContact = (contactIndex) => {
    let updateDataContactList = data?.contacts;
    updateDataContactList.splice(contactIndex, 1);
    updateData("contacts", updateDataContactList);
  };

  const onFormSubmit = () => {
    onNext();
  };
  return (
    <>
      <Form>
        <Row>
          {data?.contacts?.length > 0 &&
            data?.contacts?.map((contact, contactIndex) => (
              <React.Fragment key={"contact" + contactIndex}>
                <Col className="col-10 col-sm-11">
                  <span className="mb-2 d-block">#Contact {contactIndex + 1}</span>
                  <Row>
                    <Col className="col-12 col-md-4 mb-2">
                      <div className="form-group">
                        <label className="form-label">
                          Title <span className="text-danger fs-6">*</span>
                        </label>
                        <div className="form-control-wrap">
                          <NameTitleDropDown
                            value={data?.contacts[contactIndex]?.title ?? ""}
                            onSelect={(title) => {
                              if (data?.contacts[contactIndex]?.title !== title) {
                                setItHasChanges(true);
                              }
                              const updatedData = [...data.contacts];
                              updatedData[contactIndex].title = title;
                              updateData("contacts", updatedData);
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-md-4 mb-2">
                      <FormInput
                        label={"First Name"}
                        required={true}
                        type={FormInputType.name}
                        value={contact?.firstName}
                        // onError={(isError) => {
                        //   updateData("isFirstNameError", isError);
                        // }}
                        onTextChange={(text) => {
                          if (contact?.firstName !== text) {
                            setItHasChanges(true);
                          }
                          const updatedData = [...data.contacts];
                          updatedData[contactIndex].firstName = text;
                          updateData("contacts", updatedData);
                          // updateData("firstName", text);
                        }}
                      />
                    </Col>
                    <Col className="col-md-4 mb-2">
                      <FormInput
                        label={"Last Name"}
                        required={true}
                        type={FormInputType.name}
                        value={contact?.lastName}
                        // onError={(isError) => {
                        //   updateData("islastNameError", isError);
                        // }}
                        onTextChange={(text) => {
                          if (contact?.lastName !== text) {
                            setItHasChanges(true);
                          }
                          const updatedData = [...data.contacts];
                          updatedData[contactIndex].lastName = text;
                          updateData("contacts", updatedData);
                        }}
                      />
                    </Col>
                    <Col className="col-md-4 mb-2">
                      <FormInput
                        label={"Relationship"}
                        type={FormInputType?.default}
                        value={contact?.relationship}
                        // onError={(isError) => {
                        //   updateData("isRelationship", isError);
                        // }}
                        onTextChange={(text) => {
                          if (contact?.relationship !== text) {
                            setItHasChanges(true);
                          }
                          const updatedData = [...data.contacts];
                          updatedData[contactIndex].relationship = text;
                          updateData("contacts", updatedData);
                        }}
                      />
                    </Col>
                    <Col className="col-md-4 mb-2">
                      <FormInput
                        label={"Email"}
                        required={false}
                        type={FormInputType?.email}
                        value={contact?.email}
                        // onError={(isError) => {
                        //   updateData("isEmailError", isError);
                        // }}
                        onTextChange={(text) => {
                          if (contact?.email !== text) {
                            setItHasChanges(true);
                          }
                          const updatedData = [...data.contacts];
                          updatedData[contactIndex].email = text;
                          updateData("contacts", updatedData);
                        }}
                      />
                    </Col>
                    <Col className="col-md-4 mb-2">
                      <PhoneInput
                        phone={contact?.phoneNumber?.phoneNumber}
                        countryCode={contact?.phoneNumber?.dialCode}
                        onChange={(phoneNumber) => {
                          if (contact?.phoneNumber !== phoneNumber) {
                            setItHasChanges(true);
                          }
                          const updatedData = [...data.contacts];
                          updatedData[contactIndex].phoneNumber = phoneNumber;
                          updateData("contacts", updatedData);
                        }}
                        // onError={(isError) => {
                        //   updateData("isPhoneError", isError);
                        // }}
                      />
                    </Col>
                    <Col className="col-12 mb-2">
                      <div className="form-group">
                        <label className="form-label">Address</label>
                        <div className="form-control-wrap">
                          <textarea
                            className="form-control"
                            placeholder="Address"
                            value={contact?.Address}
                            onChange={(e) => {
                              if (contact?.Address !== e.target.value) {
                                setItHasChanges(true);
                              }
                              const updatedData = [...data.contacts];
                              updatedData[contactIndex].Address = e.target.value;
                              updateData("contacts", updatedData);
                              // updateData("Address", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col className="col-2 col-sm-1">
                  {contactIndex === 0 ? (
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <IconButton className="btn btn-primary " icon="plus" onClick={() => handleAddContact()} />
                      </div>
                    </div>
                  ) : (
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <IconButton
                          icon="minus"
                          className="btn btn-danger"
                          onClick={() => handleRemoveContact(contactIndex)}
                        />
                      </div>
                    </div>
                  )}
                </Col>
              </React.Fragment>
            ))}
        </Row>

        <Block className="text-end mt-3">
          <ul className="align-center justify-content-end flex-wrap flex-sm-nowrap gx-2 gy-2">
            <li>
              <CarerPreviousButton
                // type="submit"
                className="w-100px"
                title="Previous"
                size="md"
                onClick={() => onPrev()}
              />
            </li>
            <li>
              <ActionButton
                disabled={isDisable}
                type="button"
                className="w-120px"
                title="Save & Next"
                size="md"
                onClick={() => onFormSubmit()}
              />
            </li>
          </ul>
        </Block>
      </Form>
    </>
  );
};

export default CarereContact;
